<template>
    <div>
        <!-- 搜索栏 & 标签页 -->
        <order-search
            :start="startDate"
            :end="endDate"
            @handle-start="handleStart"
            @handle-end="handleEnd"
            @handle-search="getData">
        </order-search>
        <order-tabs
            v-model="tab"
            :tabs-list="tabList"
            @tab-click="getData">
        </order-tabs>
        <!-- 表格 -->
        <center-order-table
            table-title="制作订单"
            :table-list="orderTableList">
            <!-- 价格 -->
            <template #price="{price}">
                <div>{{"¥ " + price}}</div>
            </template>
            <!-- 实付款 -->
            <template #payment="{price}">
                <div>{{"¥ "+ price}}</div>
            </template>
            <!-- 交易状态 -->
            <!-- <template #status="{status, id}"> -->
            <template #status="{status}">
                <div>{{status | ostatus}}</div>
                <!-- <router-link 
                    :to="{
                        name: 'metopeOrder',
                        query:{id}
                    }">
                    订单详情
                </router-link> -->
            </template>
            <!-- 操作 -->
          <!-- <template #handle="{row}">
                <div class="order-handle">
              
                   <a href="javascript:;"
                        v-if="row.orderStatus == 2"
                        @click="handleStatus(row.orderID, 3)">
                        同意
                    </a>
                    
                   <a href="javascript:;"
                        v-if="row.orderStatus == 2"
                        @click="handleStatus(row.orderID, -1)">
                        拒绝
                    </a>
                    
                    <a href="javascript:;"
                        v-if="row.orderStatus == 3"
                        @click="handleStatus(row.orderID, 5)">
                        完成订单
                    </a>
                </div>
            </template>  -->
        </center-order-table>
    </div>
</template>

<script>
    import { mapState, mapMutations, mapActions } from 'vuex'
    import order from '@/mixins/centerOrder'

    export default {
        filters: {
            ostatus(val){
                switch(Number(val)){
                    case 1:
                        return "待付款";
                    case 2:
                        return "交易中";
                    case 3:
                        return "已同意";
                    case 4:
                        return "已拒绝";
                    case 9:
                        return "已完成";
                    case 6:
                        return "退款";
                    case -1:
                        return "已取消";
                }
            }
        },
        mixins: [ order ],
        data() {
            return {
                tab:"-3",
                tabList: [
                    {
                        value: '-3',
                        label: '全部'
                    },
                    {
                        value: '1',
                        label: '待付款'
                    },
                    //  {
                    //     value: '2',
                    //     label: '待确认'
                    // },
                    // {
                    //     value: '3',
                    //     label: '已同意'
                    // },
                    {
                        value: '9',
                        label: '已完成'
                    },
                    // {
                    //     value: '6',
                    //     label: '退款'
                    // },
                    {
                        value: '-1',
                        label: '已取消'
                    }
                ]
            }
        },
	
        computed: mapState({
            user_id: state => state.user.user_id,
            orderTableList: state => state.order.orderTableList
        }),
        methods: {
            ...mapMutations([
                'handleLoading',
                'setCenterOrder'
            ]),
            ...mapActions([
                'getCenterOrder',
                'handleOrderStatus'
            ]),
            getParams() {
                let userID = this.user_id,
                    fromTime = this.startDate,
                    toTime = this.endDate,
                    orderStatus = this.tab == '-2'? '0': this.tab;
                return this.$qs.stringify({
                    userID,
                    fromTime,
                    toTime,
                    orderStatus,
                    type:2
                })
            },
            getData() {
                let url = '/usercenter/makerorder',
                    params = this.getParams();
                this.getCenterOrder({
                    url,
                    params
                })
            },
            handleStatus(orderID, status) {
                let statusName = '';
                switch (status){
                    case 3:
                        statusName = '同意';
                        break;
                    case -1:
                        statusName = '拒绝';
                        break;
                    case 4:
                        statusName = '完成';
                        break;
                }

                this.$confirm(`是否${statusName}订单？`, '提示' ,{
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.handleLoading(true);
                
                    let url = '/usercenter/ushopOrderStatus',
                        params = this.$qs.stringify({
                            userID: this.user_id,
                            orderID,
                            status
                        });
                    
                    return this.handleOrderStatus({
                        url,
                        params
                    })
                }).then(res => {
                    let code = res.data.code == 200;
                    this.$message({
                        showClose: true,
                        message: res.data.message,
                        type: code? 'success': 'error'
                    })
                    code && this.getData();
                }).catch(() => {
                }).finally(() => {
                    this.handleLoading(false);
                })
            }
        },
        beforeRouteEnter (to, from, next) {
            next(vm => {
                vm.getData();
            })
        },
        beforeRouteUpdate(to, from, next) {
            next();
        },
        beforeRouteLeave (to, from, next) {
            this.setCenterOrder([]);
            next();
        }
    }
</script>

<style scoped>
.order-handle > a {
    display: block;
}
</style>