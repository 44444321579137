export default {
    data() {
        let startDate = sessionStorage.getItem('startDate') || '',
            endDate = sessionStorage.getItem('endDate') || '';
        return {
            startDate,
            endDate,
            tab: '-2',
        }
    },
    components: {
        orderSearch: () => import('@/components/centerOrder/order-search'),
        orderTabs: () => import('@/components/centerOrder/order-tabs'),
        centerOrderTable: () => import("@/components/centerOrderTable")
    },
    methods: {
        handleStart(date) {
            let value = '';
            date && (value = date);
            sessionStorage.setItem('', value);
            this.startDate = value;
            if(this.endDate < this.startDate) {
                this.endDate = '';
            }
        },
        handleEnd(date) {
            let value = '';
            date && (value = date);
            sessionStorage.setItem('', value);
            this.endDate = value;
        }
    }
}